import axios from "axios";
import { useQuery } from "react-query";
import { Parkings } from "../types/parking";
import { User } from "../types/user";

const useUser = (phoneNumber: string) => {
  return useQuery(["user", phoneNumber], async () => {
    return (await (
      await axios.post("/user", {
        phoneNumber,
      })
    ).data) as User;
  });
};

export { useUser };
