import {
  FullHistoryTable,
  ParkingHistoryTable,
  UserHistoryTable,
} from "../components/FullHistoryTable";

const LandingPage = () => {
  return (
    <div style={{ height: "80%" }}>
      <h1>Sistem za kontrolu pristupa parkingu</h1>
      <p>
        Sa leve strane možete videti sve parkinge koje imate pristup da
        administrirate. Nakon odabira parkinga, možete videti sve korisnike koji
        imaju ili su imali pristup tom parkingu. Odabirom korisnika iz tabele,
        možete videti dodatne informacije o tom korisniku, kao i da zavede novo
        plaćanje i omogućite mu pristup korišćenja parkinga. Moguće je uneti
        cenu od 0 dinara za besplatno korišćenje parkinga.
      </p>
    </div>
  );
};

export default LandingPage;
