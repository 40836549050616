import axios from "axios";
import { useQuery } from "react-query";
import Notifications from "../types/notifications";

const useNotifications = () => {
  return useQuery(
    "notifications",
    async () =>
      (await (
        await axios.get("/notifications")
      ).data) as Notifications,
    {
      refetchInterval: 5000,
    }
  );
};

export default useNotifications;
