import axios from "axios";
import { useQuery } from "react-query";

const useUserInfo = () => {
  return useQuery(
    "userinfo",
    async () => await (await axios.get("/userinfo")).data,
    {
      retry: false,
    }
  );
};

export default useUserInfo;
