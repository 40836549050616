import { Link, Outlet, useParams } from "react-router-dom";
import Logo from "../images/logo";

import useParkings from "../http/useParkings";
import LoadingAnimation from "../components/LoadingAnimation";

const DashboardPage = () => {
  const { parkingId } = useParams();

  const { data: parkings, isLoading } = useParkings();

  return (
    <div style={{ height: "100%", display: "flex", columnGap: "10px" }}>
      <div
        style={{
          height: "100%",
          overflowY: "auto",
          paddingRight: "1em",
          paddingLeft: "1em",
          width: "20em",
          minWidth: "20em",
        }}
      >
        <Logo />
        {isLoading && !parkings ? (
          <LoadingAnimation fullScreen={false} />
        ) : (
          parkings?.map((i) => (
            <DashboardParkingItem
              name={i.name}
              address={i.address}
              maxSpaces={i.maxSpaces}
              key={i.parkingId}
              active={i.parkingId.toString() === parkingId}
              onClickHref={i.parkingId}
            />
          ))
        )}
      </div>
      <div
        style={{
          flexGrow: 1,
          flexShrink: 1,
          overflowY: "auto",
          paddingTop: "3em",
          paddingRight: "1em",
        }}
      >
        <Outlet />
      </div>
    </div>
  );
};

const DashboardParkingItem = ({
  name,
  address,
  maxSpaces,
  active,
  onClickHref,
}: any) => {
  return (
    <Link to={`/parking/${onClickHref}`} relative="path">
      <div
        className={`dashboard-parking-item ${
          active ? "dashboard-parking-item-active" : ""
        }`}
      >
        <div className="dashboard-parking-item-left">
          <h5>{name}</h5>
          <p>{address}</p>
        </div>
        <div className="dashboard-parking-item-counter">
          {/*  {hourlyFee} <small style={{ fontSize: "0.5em" }}>RSD/h</small> */}
          {maxSpaces}
        </div>
      </div>
    </Link>
  );
};

export default DashboardPage;
export { DashboardParkingItem };
