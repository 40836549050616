import axios from "axios";
import { useQuery } from "react-query";
import { Parkings } from "../types/parking";

const useParkings = () => {
  return useQuery(
    "parkings",
    async () => (await (await axios.get("/parkings")).data) as Parkings
  );
};

export default useParkings;
