import { FC } from "react";

interface LoadingAnimationProps {
  fullScreen?: boolean;
}

const LoadingAnimation: FC<LoadingAnimationProps> = ({ fullScreen }) => {
  return (
    <div
      className={`d-flex justify-content-center align-items-center w-100 ${
        fullScreen === false ? "" : "h-100"
      }`}
    >
      <div className="circle-animated"></div>
    </div>
  );
};

export default LoadingAnimation;
