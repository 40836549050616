import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { FC } from "react";
import { FaBell } from "react-icons/fa6";
import useNotifications from "../http/useNotifications";
import { formatDateTime } from "../utils/formaters";
import LoadingAnimation from "./LoadingAnimation";

interface NotificationPanelProps {
  isOpen: boolean;
  close: () => void;
}

const NotificationPanel: FC<NotificationPanelProps> = ({ isOpen, close }) => {
  const { data, isLoading, refetch } = useNotifications();

  const markNotificationAsRead = async (id: string | number) => {
    try {
      await axios.post(`/notification/${id}`);
      refetch();
    } catch (e) {
      enqueueSnackbar(
        "Došlo je do greške prilikom označavanja obaveštenja kao pročitanog.",
        {
          variant: "error",
        }
      );
    }
  };

  const notificationPanelClass = `notification-panel ${
    isOpen ? "" : "notification-panel-hidden"
  }`;

  if (!data && isLoading)
    return (
      <div className={notificationPanelClass}>
        <LoadingAnimation />
      </div>
    );

  if (!data) return <p>Došlo je do greške prilikom učitavanja obaveštenja.</p>;

  return (
    <>
      {isOpen ? (
        <div
          className="fade modal-backdrop notification-backdrop show"
          onClick={close}
        ></div>
      ) : null}
      <div className={notificationPanelClass}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <h3>
            <FaBell /> Obaveštenja
          </h3>
        </div>
        {data.notifications.map((notification) => (
          <div
            key={notification.id}
            className={`notification-item notification-item-${
              notification.isRead ? "read" : "unread"
            }`}
          >
            <div className="notification-item-meta">
              <p
                className="notification-mark-read"
                onClick={() => markNotificationAsRead(notification.id)}
              >
                Označi kao pročitano
              </p>
              <div style={{ flex: 1 }}></div>
              <p className="notification-time">
                {formatDateTime(notification.time)}
              </p>
            </div>
            <p>{notification.message}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default NotificationPanel;
