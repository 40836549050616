import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  FormSelect,
  Modal,
  Row,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import { UserHistoryTable } from "../components/FullHistoryTable";
import LoadingAnimation from "../components/LoadingAnimation";
import useParkings from "../http/useParkings";
import { useUser } from "../http/useUser";
import { formatCurrency, formatDate, formatDateTime } from "../utils/formaters";
import { DashboardParkingItem } from "./DashboardPage";

const getStatus = (status: string) => {
  switch (status) {
    case "PENDING":
      return "Na čekanju";
    case "CLEARING":
      return "Knjiži se";
    case "CLEARED":
      return "Proknjižen";
    case "CANCELLED":
      return "Otkazan";
    default:
      return "Nepoznat";
  }
};

const getType = (type: string) => {
  switch (type) {
    case "CASH":
      return "Gotovina";
    case "MOBILE":
      return "SMS";
    default:
      return "Nepoznat";
  }
};

const UserParkingPage = () => {
  const { userPhoneNumber } = useParams();
  const { data, refetch } = useUser(userPhoneNumber ?? "");
  const { data: parkings } = useParkings();

  const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);

  const [today, setToday] = useState(new Date());

  const [oneMonthFromToday, setMonthFromToday] = useState(new Date());

  useEffect(() => {
    const todayy = new Date();
    setToday(todayy);
    const monthFromToday = new Date();
    monthFromToday.setMonth(todayy.getMonth() + 1);
    setMonthFromToday(monthFromToday);
  }, []);

  if (!userPhoneNumber) {
    return <h1>Greška</h1>;
  }

  if (!data || !parkings) {
    return <LoadingAnimation />;
  }

  const user = data?.userDetails;

  return (
    <>
      <Container fluid style={{ padding: "2em" }}>
        <Row>
          <Col md={8}>
            <h1>
              {user.name} {user.surname}
            </h1>
            <h3>{user.phoneNumber}</h3>
            <p>Poslednja aktivnost: {formatDate(user.lastActivity)}</p>
            <h3>Vaši parkinzi na kojima korisnik ima mogućnost zakupa mesta</h3>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1em",
                width: "100%",
                flexWrap: "wrap",
                paddingTop: "1em",
              }}
            >
              {user.whitelists.map((i, index) => {
                const parking = parkings.find(
                  (p) => p.parkingId === i.parkingId
                )!;
                const subscription = user.subscriptions.find(
                  (s) => s.parkingId === i.parkingId && s.isActive
                );
                return (
                  <div style={{ width: "20em" }} className="small-bottom">
                    <DashboardParkingItem
                      name={parking.name}
                      address={parking.address}
                      maxSpaces={
                        subscription
                          ? formatDate(subscription.leaseTo)
                          : "Nema zakupa"
                      }
                      key={i.parkingId + index}
                      active={false}
                      onClickHref={i.parkingId}
                    />
                  </div>
                );
              })}
            </div>
            <br />
          </Col>
          <Col md={4}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h2>Istorija uplata</h2>
              <div style={{ flexGrow: 1 }}></div>
              <button
                className="btn btn-hover-light"
                onClick={() => {
                  setShowAddPaymentModal(true);
                }}
              >
                + Dodaj novo plaćanje
              </button>
            </div>
            <br />
            {user.payments.map((payment, index) => {
              const parking = parkings.find(
                (p) => p.parkingId === payment.parkingId
              )!;
              const subscription = user.subscriptions.find(
                (s) =>
                  payment.subscriptionUuid === s.subscriptionUuid &&
                  payment.subscriptionUuid !== null
              );
              const park = user.parks.find(
                (p) =>
                  p.parkUuid === payment.parkUuid && payment.parkUuid !== null
              );
              return (
                <div
                  className="dashboard-parking-item"
                  style={{ width: "100%", marginBottom: "1em" }}
                  key={index}
                >
                  <div className="dashboard-parking-item-left">
                    <h5>{formatCurrency(payment.amount)} </h5>
                    <p className="mb-0">
                      Vreme uplate {formatDateTime(payment.paymentTime)}
                    </p>
                    <p className="mb-0">Status: {getStatus(payment.status)}</p>
                    <p className="mb-0">
                      Način plaćanja: {getType(payment.paymentType)}
                    </p>
                    {subscription ? (
                      <p className="mb-0">
                        Zakup mesta na parkingu {parking.name} od{" "}
                        {formatDateTime(subscription.leaseFrom)} do{" "}
                        {formatDateTime(subscription.leaseTo)}
                      </p>
                    ) : null}
                    {park ? (
                      <p className="mb-0">
                        Parking na sat na parkingu {parking.name} od{" "}
                        {formatDateTime(park.enterTime)} do{" "}
                        {formatDateTime(park.exitTime)}
                      </p>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </Col>
        </Row>
      </Container>
      <Modal
        show={showAddPaymentModal}
        onHide={() => {
          setShowAddPaymentModal(false);
        }}
      >
        <Form
          onSubmit={async (e) => {
            e.preventDefault();
            if ((e.target as HTMLFormElement).checkValidity()) {
              const formData = new FormData(e.target as HTMLFormElement);
              const parkingId = formData.get("parkingId") as string;
              const fee = formData.get("fee") as string;
              const startDate = new Date(
                new Date().toISOString().split("T")[0] // Just so reset to midnight
              );
              const endDate = new Date(formData.get("endDate") as string);

              try {
                await axios.post("user/subscribe", {
                  phoneNumber: userPhoneNumber,
                  parkingId,
                  fee,
                  startTime: startDate.toISOString().replace(".000Z", ""),
                  endTime: endDate.toISOString().replace(".000Z", ""),
                });
                enqueueSnackbar("Uspešno dodato plaćanje", {
                  variant: "success",
                });
                refetch();
                setShowAddPaymentModal(false);
              } catch (e) {
                enqueueSnackbar("Greška prilikom dodavanja plaćanja", {
                  variant: "error",
                });
              }
            }
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Dodaj plaćanje</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup className="mb-3">
              <FormLabel>Parking</FormLabel>
              <FormSelect inputMode="numeric" name="parkingId">
                {parkings.map((parking) => (
                  <option value={parking.parkingId}>{parking.name}</option>
                ))}
              </FormSelect>

              <small className="form-text text-muted">
                Parking na kom se vrši zakup
              </small>
            </FormGroup>
            <FormGroup className="mb-3">
              <FormLabel>Cena</FormLabel>
              <FormControl
                type="number"
                defaultValue=""
                inputMode="numeric"
                name="fee"
                min={0}
              />
              <small className="form-text text-muted">
                Cena zakupa parkinga za navedeni period
              </small>
            </FormGroup>
            <FormGroup className="mb-3">
              <FormLabel>Krajnji datum zakupa</FormLabel>
              <FormControl
                type="date"
                defaultValue={oneMonthFromToday.toISOString().split("T")[0]}
                name="endDate"
                required
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-hover-light btn-danger"
              onClick={() => {
                setShowAddPaymentModal(false);
              }}
            >
              Otkaži
            </Button>
            <Button className="btn-hover-light" type="submit">
              Dodaj
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default UserParkingPage;
