const formatDate = (date: Date | string) => {
  return new Date(date).toLocaleDateString("sr-RS");
};

const formatDateTime = (date: Date | string) => {
  return new Date(date).toLocaleString("sr-RS");
};

// TODO: Fix
const formatDifference = (date: Date | string) => {
  const diff = Math.floor(
    (new Date(date).getTime() - new Date().getTime()) / 1000
  );
  if (diff < 0) return "Isteklo";
  const months = Math.floor(diff / (60 * 60 * 24 * 30));
  const days = Math.floor((diff % (60 * 60 * 24 * 30)) / (60 * 60 * 24));
  if (months > 0) return `${months}m ${days}d`;
  return `${days}d`;
};

const formatCurrency = (amount: number) =>
  new Intl.NumberFormat("sr-RS", {
    style: "currency",
    currency: "RSD",
  }).format(amount);

const formatPhoneNumber = (phoneNumber: string) => {
  return (
    phoneNumber.substring(0, 4) +
    " " +
    phoneNumber.substring(4, 6) +
    " " +
    phoneNumber.substring(6)
  );
};

export {
  formatDate,
  formatDateTime,
  formatDifference,
  formatCurrency,
  formatPhoneNumber,
};
