import "./App.css";

import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import DashboardPage from "./pages/DashboardPage";
import LoginPage from "./pages/LoginPage";

import axios from "axios";
import { SnackbarProvider } from "notistack";
import { FaBell, FaMoon, FaRegBell } from "react-icons/fa6";
import { RxAvatar, RxHome } from "react-icons/rx";
import { QueryClient, QueryClientProvider } from "react-query";
import LoadingAnimation from "./components/LoadingAnimation";
import NotificationPanel from "./components/NotificationPanel";
import useUserInfo from "./http/useUserInfo";
import ParkingPage from "./pages/ParkingPage";
import UserPage from "./pages/UserPage";
import LandingPage from "./pages/LandingPage";
import useNotifications from "./http/useNotifications";

axios.defaults.baseURL = "/api/v0/admin";

const allowedThemes = ["dark", "light"];

const queryClient = new QueryClient();

function App() {
  const [currentTheme, setCurrentTheme] = useState("light");

  const setTheme = (newTheme: string) => {
    setCurrentTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  const getSavedTheme = React.useCallback(() => {
    const newTheme = localStorage.getItem("theme");
    if (newTheme && allowedThemes.includes(newTheme)) {
      setTheme(newTheme);
    } else {
      setTheme("light");
    }
  }, []);

  const onStorageThemeChange = React.useCallback((event: StorageEvent) => {
    if (event.key === "theme") {
      setTheme(event.newValue || "light");
    }
  }, []);

  useEffect(() => {
    getSavedTheme();
    window.addEventListener("storage", onStorageThemeChange);

    return () => {
      window.removeEventListener("storage", onStorageThemeChange);
    };
  }, [getSavedTheme, onStorageThemeChange]);

  const toggleTheme = () => {
    setTheme(currentTheme === "dark" ? "light" : "dark");
  };

  useEffect(() => {
    document.body.dataset.bsTheme = currentTheme;
  }, [currentTheme]);

  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <CheckAuth>
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={
                  <LoggedInRouter
                    toggleTheme={toggleTheme}
                    currentTheme={currentTheme}
                  />
                }
              >
                <Route path="" element={<Navigate to="parking" replace />} />
                <Route path="parking" element={<DashboardPage />}>
                  <Route path="" element={<LandingPage />} />
                  <Route path=":parkingId" element={<ParkingPage />} />
                </Route>
                <Route path="user/:userPhoneNumber" element={<UserPage />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </CheckAuth>
      </SnackbarProvider>
    </QueryClientProvider>
  );
}

// TODO: Fix children type
const CheckAuth: React.FC<{ children: any }> = ({ children }) => {
  const { data: user, error } = useUserInfo();

  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if ((user || error) && initialLoad) setInitialLoad(false);

    if (user && user.status === 401) {
      window.location.reload();
    }
  }, [user, error, initialLoad]);

  if (initialLoad && !user) {
    return (
      <div style={{ height: "100vh" }}>
        <LoadingAnimation />
      </div>
    );
  }

  if (user) {
    return children;
  }

  return <LoginPage />;
};

const LoggedInRouter = ({
  toggleTheme,
  currentTheme,
}: {
  toggleTheme: any;
  currentTheme: string;
}) => {
  const [notificationOpen, setNotificationOpen] = useState(false);

  const { data: notifications } = useNotifications();

  const notificationCount =
    notifications?.notifications.filter((notification) => !notification.isRead)
      .length || 0;

  const toggleNotification = () => {
    setNotificationOpen((value) => !value);
  };

  return (
    <>
      <NotificationPanel isOpen={notificationOpen} close={toggleNotification} />
      <div id="myroot">
        <div id="header">
          <div>
            <Link to="/parking">
              <RxHome />
            </Link>
          </div>
          <div style={{ flex: 1 }}></div>
          <div>
            <div style={{ position: "relative" }} onClick={toggleNotification}>
              {notificationOpen ? <FaBell /> : <FaRegBell />}
              <div
                className={`notification-badge ${
                  notificationCount !== 0 ? "" : "d-none"
                }`}
              >
                {notificationCount > 9 ? "9+" : notificationCount}
              </div>
            </div>
            <FaMoon
              style={{
                color: currentTheme === "dark" ? "orange" : "white",
              }}
              onClick={toggleTheme}
            >
              C
            </FaMoon>
            <RxAvatar />
          </div>
        </div>
        <div id="content">
          <div style={{ height: "100%" }}>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default App;
