import { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  Table,
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";

import axios from "axios";
import { enqueueSnackbar } from "notistack";
import LoadingAnimation from "../components/LoadingAnimation";
import { useParking } from "../http/useParking";
import {
  formatCurrency,
  formatDate,
  formatDifference,
  formatPhoneNumber,
} from "../utils/formaters";

const ParkingPage = () => {
  const { parkingId } = useParams();
  const navigate = useNavigate();

  const [showAddUserModal, setShowAddUserModal] = useState(false);

  const { data, refetch } = useParking(parkingId ?? "");

  const [today, setToday] = useState(new Date());

  const [oneMonthFromToday, setMonthFromToday] = useState(new Date());

  useEffect(() => {
    const todayy = new Date();
    setToday(todayy);
    const monthFromToday = new Date();
    monthFromToday.setMonth(todayy.getMonth() + 1);
    setMonthFromToday(monthFromToday);
  }, []);

  if (!parkingId) return null; // This should never happen

  return data ? (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
        }}
      >
        <div>
          <h1>{data.parkingName}</h1>
          <h3>{data.parkingAddress}</h3>
        </div>
        <div
          style={{
            textAlign: "center",
            display: "flex",
            columnGap: "1em",
            marginRight: "3em",
          }}
        >
          <div>
            <h3>{data.parkingMaxSpaces - data.parkingLeasedSpaces}</h3>
            <p>Slobodno mesta</p>
          </div>
          <div>
            <h3>{data.parkingLeasedSpaces}</h3>
            <p>Izdato mesta</p>
          </div>
          <div>
            <h3>{data.parkingMaxSpaces}</h3>
            <p>Ukupno mesta</p>
          </div>
        </div>
      </div>
      <br />

      <div>
        <div style={{ display: "flex", alignItems: "end" }}>
          <h5>Pregled korisnika parkinga</h5>
          <div style={{ flex: 1 }} />
          <button
            className="btn btn-hover-light"
            onClick={() => setShowAddUserModal(true)}
          >
            + Dodaj korisnika
          </button>
        </div>
        <Table className="table-striped table-hover table-fixed">
          <thead className="table-primary">
            <tr>
              <th style={{ width: "13%" }}>Korisnik</th>
              <th style={{ width: "10%" }}>Info</th>
              <th style={{ width: "10%" }}>Broj telefona</th>
              <th style={{ width: "10%" }}>Važi do</th>
              <th style={{ width: "5%" }}></th>
            </tr>
          </thead>
          <tbody>
            {[...data.parkingSubscriptions, ...data.parkingWhitelists].map(
              (user, index) => (
                <tr key={index}>
                  <td>
                    {user.userName
                      ? `${user.userName} ${user.userSurname}`
                      : "Nije registrovan"}
                  </td>
                  <td>{user.userInfo}</td>
                  <td>{formatPhoneNumber(user.userPhoneNumber)}</td>
                  <td>
                    {(user as any).leaseTo
                      ? `${formatDate(
                          (user as any).leaseTo
                        )} (${formatDifference((user as any).leaseTo)})`
                      : "Nije uplaćeno"}
                  </td>
                  <td>
                    <Link to={`/user/${user.userPhoneNumber}`}>
                      <b style={{ color: "red" }}>KLIKNI ME</b>
                    </Link>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </Table>

        <br />
      </div>
      <Modal
        show={showAddUserModal}
        onHide={() => {
          setShowAddUserModal(false);
        }}
      >
        <Form
          onSubmit={async (e) => {
            e.preventDefault();
            if ((e.target as HTMLFormElement).checkValidity()) {
              const formData = new FormData(e.target as HTMLFormElement);
              const phoneNumber = formData.get("phoneNumber") as string;
              const fee = 0;
              const adminInfo = formData.get("adminInfo") as string;
              try {
                await axios.post(`user/whitelist`, {
                  phoneNumber,
                  fee,
                  adminInfo,
                  parkingId,
                });
                enqueueSnackbar("Uspešno dodat korisnik", {
                  variant: "success",
                });
                setShowAddUserModal(false);
                refetch();
                navigate(`/user/${phoneNumber}`);
              } catch (e) {
                enqueueSnackbar("Greška prilikom dodavanja korisnika", {
                  variant: "error",
                });
              }
            }
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Dodaj korisnika</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup className="mb-3">
              <FormLabel>Broj telefona</FormLabel>
              <FormControl
                type="text"
                className="form-control"
                required
                defaultValue="+381"
                inputMode="tel"
                pattern="^\+381[ 0-9]+$"
                name="phoneNumber"
              />
              <small className="form-text text-muted">
                Obavezno je uneti pozivni broj (+381 za srpske brojeve)
              </small>
            </FormGroup>
            <FormGroup className="mb-3">
              <FormLabel>Komentar</FormLabel>
              <FormControl
                type="text"
                className="form-control"
                name="adminInfo"
              />
              <small className="form-text text-muted">
                Kratki komentar o korisniku. Na primer: Stan 13
              </small>
            </FormGroup>
            <p className="form-text m-0">
              Ukoliko korisnik odmah plaća parking, unos toga možete uraditi na
              narednom ekranu
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-hover-light btn-danger"
              onClick={() => {
                setShowAddUserModal(false);
              }}
            >
              Otkaži
            </Button>
            <Button className="btn-hover-light" type="submit">
              Dodaj
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  ) : (
    <LoadingAnimation />
  );
};

export default ParkingPage;
