import { enqueueSnackbar } from "notistack";
import { Button, Form } from "react-bootstrap";
import login from "../http/login";
import useUserInfo from "../http/useUserInfo";
import Logo from "../images/logo";

const LoginPage = () => {
  const width = 500;
  const height = 700;

  const { refetch } = useUserInfo();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      username: { value: string };
      password: { value: string };
    };
    try {
      console.log("Ovo je unutar try catch");
      await login(target.username.value, target.password.value);
      refetch();
    } catch (error) {
      enqueueSnackbar("Greška prilikom prijave", { variant: "error" });
    }
  };

  return (
    <div
      style={{
        maxWidth: width,
        maxHeight: height,
        margin: "auto",
        marginTop: `calc(50vh - ${height / 2}px)`,
      }}
    >
      <Logo />
      <Form onSubmit={onSubmit}>
        <Form.Group className="mb-3" controlId="formBasicUsername">
          <Form.Label>Korisničko ime</Form.Label>
          <Form.Control
            type="text"
            placeholder="Unesite korisničko ime"
            name="username"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Lozinka</Form.Label>
          <Form.Control
            type="password"
            placeholder="Unesite lozinku"
            name="password"
          />
        </Form.Group>
        <Button variant="primary w-100" type="submit">
          Prijavi se
        </Button>
      </Form>
    </div>
  );
};

export default LoginPage;
