import axios from "axios";
import { useQuery } from "react-query";
import { ParkingFull, ParkingGroup } from "../types/parking";

const useParking = (id: string) => {
  return useQuery(["parking", id], async () => {
    if (id === "") {
      return null;
    }
    return (await (
      await axios.get(`/parking/${id}`)
    ).data) as ParkingFull;
  });
};

const useParkingGroup = (parkingId: string) => {
  return useQuery(
    ["parking", "group", parkingId],
    async () =>
      (await (
        await axios.get(`user/groups`, { params: { parkingId } })
      ).data) as ParkingGroup
  );
};

export { useParking, useParkingGroup };
